<template>
  <div class="flex items-center progress">
    <div class="w-11/12 h-full border-b">
      <div class="flex justify-between items-center" style="height:50px;">
        <div class>{{ title }}</div>
        <div class="text-2xl text-right">{{ count }}</div>
      </div>
      <progress-bar-x
        :value="value"
        :animated="true"
        :height="5"
        :radius="60"
      />
    </div>
    <div
      class="hidden justify-end items-center pt-2 w-1/6 h-full md:flex md:ml-0"
    >
      <div class="flex justify-end items-center">
        <span v-html="percentageIcon"></span>
        <span v-html="percentageString" class="ml-1"></span>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBarX from '@/components/analytics/charts/ProgressBarX'

export default {
  name: 'HorizontalBarChart',
  components: {
    ProgressBarX,
  },
  props: {
    title: {
      type: String,
    },
    count: {
      type: Number,
    },
    value: {
      type: Number,
    },
    summaryPercentage: {
      type: Number,
    },
    isSummaryPercentagePositive: {
      type: Boolean,
      default: true,
    },
    compare: {
      required: false,
    },
  },
  computed: {
    percentageIcon: function() {
      if (this.isSummaryPercentagePositive === true) {
        return '<i class="fas fa-arrow-up text-oGreen"></i>'
      }
      return '<i class="fas fa-arrow-down text-oRed"></i>'
    },
    percentageString: function() {
      if (this.isSummaryPercentagePositive === true) {
        return `<div class="p-positive">${this.summaryPercentage}%</div>`
      }
      return `<div class="p-negative">${this.summaryPercentage}%</div>`
    },
  },
}
</script>

<style>
.icon-up,
.icon-down {
  height: 15px;
  width: 15px;
}
.icon-up,
.p-positive {
  color: #09aa35;
}
.icon-down,
.p-negative {
  color: #d90a20;
}
</style>
